.slick-arrow.slick-prev ,
.slick-arrow.slick-next{
    background: transparent;
    border: 1px solid #E5E3DA;
    padding: initial;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 5;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:hover{
    border: 1px solid #74642F;
    background-color: #E5E3DA;
}
.slick-arrow.slick-prev::before ,
.slick-arrow.slick-next::before{
    color: #8C8C8C;
}