@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?23qo56');
  src:  url('fonts/icomoon.eot?23qo56#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?23qo56') format('truetype'),
    url('fonts/icomoon.woff?23qo56') format('woff'),
    url('fonts/icomoon.svg?23qo56#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ns-arrow-right:before {
  content: "\e90b";
}
.icon-cancel:before {
  content: "\e904";
}
.icon-arrow-left-circle:before {
  content: "\e902";
}
.icon-arrow-right-circle:before {
  content: "\e903";
}
.icon-user:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e905";
}
.icon-notes:before {
  content: "\e906";
}
.icon-clipboard:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e909";
}
.icon-currency-dollar:before {
  content: "\e90a";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-behance-square:before {
  content: "\f1b5";
}
.icon-paper-plane:before {
  content: "\f1d8";
}
.icon-send:before {
  content: "\f1d8";
}
.icon-pinterest-p:before {
  content: "\f231";
}
